.container h2 {
  font-size: 2.25rem; /* text-4xl */
  font-weight: 600; /* font-semibold */
  margin-top: 1rem; /* mt-4 */
}

.container h3 {
    font-size: 1.5rem; /* text-2xl */
    font-weight: 600; /* font-semibold */
    margin-top: 2rem; /* mt-8 */
  }

  .container h4 {
    font-size: 1.25rem; /* text-xl */
    font-weight: 600; /* font-semibold */
    margin-top: 1.75rem; 
  }  
  
  .container p {
    margin-top: 1rem; /* mb-4 */
  }

  .container ul {
    list-style-type: none;
    padding-left: 1.5rem; /* pl-6 */
    margin-top: 1rem; /* mt-4 */
    margin-bottom: 1rem; /* mb-4 */
  }
  
  .container li {
    margin-bottom: 0.5rem; /* mb-2 */
  }
  
  .container li > ul {
    list-style-type: disc;
    margin-top: 0.5rem; /* mt-2 */
  }

  .container a {
    color: #3b82f6; /* text-blue-500 */
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
  }
  
  .container a:hover {
    color: #1d4ed8; /* text-blue-700 */
  }

  .controllerImage {
    margin-top: 1.0rem; /* mt-2 */    
    width: 300; /* Set this to your desired width */
    height: 200px; /* Set this to your desired height */
    object-fit: cover; /* This ensures the image covers the area without distortion */
    /*margin: 1rem auto; /* Centers the image horizontally and adds vertical margin */
    /*display: block; /* Allows margin auto to center the image */
  }

  .kontaktImage {
    margin-top: 1.0rem; /* mt-2 */    
    width: 200; /* Set this to your desired width */
    height: 44px; /* Set this to your desired height */
    object-fit: cover; /* This ensures the image covers the area without distortion */
    /*margin: 1rem auto; /* Centers the image horizontally and adds vertical margin */
    /*display: block; /* Allows margin auto to center the image */
  }